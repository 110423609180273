export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCGhkLZ05MEvOv0xhVVhgB2mlySC17GTqQ",
    authDomain: "orlichat-sasafeme.firebaseapp.com",
    databaseURL: "https://orlichat-sasafeme-default-rtdb.firebaseio.com",
    projectId: "orlichat-sasafeme",
    storageBucket: "orlichat-sasafeme.appspot.com",
    messagingSenderId: "108118384699",
    appId: "1:108118384699:web:2ad93e14779f3d5a07bc1e",
    measurementId: "G-80WKEZJ6KE"
  },
  googleClientId: "108118384699-d429uviek8sk576n9qn8ffudoggcskia.apps.googleusercontent.com"
};
